<template>
  <v-card>
    <v-card-title class="text-h5 font-weight-regular"> Filter MFS List </v-card-title>
    <v-card-text>
      <v-row justify="center" class="ma-5">
        <v-col md="6">
          <v-combobox
            v-model="formValues['type']"
            :items="typeOptions"
            label="Type"
            class="pa-0 mb-1"
            outlined
            hide-details
          />
        </v-col>
        <v-col md="6">
          <v-combobox
            class="pa-0 mb-1"
            outlined
            hide-details
            v-model="formValues['status']"
            :items="statusOptions"
            label="Status"
          />
        </v-col>
        <v-col md="6" v-for="item in Object.keys(formItems)" :key="item">
          <v-text-field
            class="pa-0 mb-1"
            outlined
            hide-details
            :label="prettify(item)"
            v-model="formValues[item]"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="filterSelected"> Filter </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "Filters",
  props: {
    initialValues: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted() {
    this.formValues = this.initialValues || {};
  },
  data() {
    return {
      formValues: {},
      formItems: {
        agentMobileNo: "string",
        bloodGroup: "string",
        dateOfBirth: "2021-12-02",
        districtCode: "string",
        divisionCode: "string",
        email: "string",
        fatherName: "string",
        firstName: "string",
        gender: "Female",
        lastName: "string",
        mobileNo: "string",
        motherName: "string",
        nid: "string",
        nomineeMobileNo: "string",
        nomineeName: "string",
        nomineeRelation: "string",
        operatorCode: "string",
        permanentAddress: "string",
        postalCode: "string",
        presentAddress: "string",
        profession: "string",
        referral: "string",
        spouseName: "string",
        thanaCode: "string",
        startTime: 0,
        stopTime: 0,
      },
      typeOptions: ["Self", "Assisted"],
      statusOptions: ["New", "Reviewed", "Approved", "Completed", "Rejected", "Failed"],
    };
  },
  methods: {
    filterSelected() {
      if (this.formValues["status"]) {
        this.formValues["status"] = _.upperCase(this.formValues["status"]);
      }
      if (this.formValues["type"]) {
        this.formValues["type"] = "MFS_" + _.upperCase(this.formValues["type"]);
      }
      this.$emit("filterSelected", this.formValues);
    },
    prettify(name) {
      return _.startCase(name);
    },
  },
};
</script>

<style scoped></style>
